// src/HeroSection.js
import React from 'react';
// import image from './path-to-your-image.jpg'; // Add the path to the image here
import service from './Image/service.jpeg'

import "./styles/Services.css"
import pca from "./Icons/pca.png"
import iot from "./Icons/iot.png"
import ins from "./Icons/ins.png"
import ds1 from "./Icons/ds1.png"
import ds from "./Icons/ds.png"
import ems from "./Icons/ems.png"
import cm from "./Icons/cm.png"

const Service = () => {
    return (
        <>

            <div className="services-section">
                <div data-aos="flip-left"><br />
                    <h2 style={{ textAlign: "center", color: "#4184b0", marginTop: "15px"}}> Ours Services</h2>
                </div><br />
                <p >
                    Our services provide comprehensive solutions tailored to meet your unique needs. We specialize in delivering high-quality, reliable services that enhance efficiency and drive growth.</p>

                <div className="services-cards">
                    <div data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">


                        <div className="service-card">
                            <div className="service-icon">
                                <img src={pca} alt="" style={{ height: "50%", width: "50%" }} />
                            </div>

                            <h3>Process Control Automation </h3>

                            <p style={{ textAlign: 'inherit' }}>process control automation, ensuring precision, efficiency, and reliability in your operations. Our solutions are tailored to meet the specific needs of each customer, optimizing processes and reducing downtime.</p>
                        </div>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={iot} alt="" style={{ height: "50%", width: "40%" }} />
                        </div>
                        <h3>IIoT Software Solution</h3>
                        <p style={{ textAlign: "left" }}> Our IIoT solution adapts ensuring maximum efficiency and cost savings. Backed by robust security features and expert, we deliver a solution your expectations, driving your business more connected future.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={ins} alt="" style={{ height: "40%", width: "40%" }} />
                        </div>
                        <h3 style={{ textAlign: "left" }}>Industrial Networking Solution</h3>
                        <p style={{ textAlign: "left" }}>Our Solution ensures seamless, secure connectivity across your operations. robust performance, real-time data access, we empower your business thrive in a connected world.</p>
                    </div>
                </div>

                <div className="services-cards">
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={ds1} alt="" style={{ height: "40%", width: "30%" }} />
                        </div>
                        <h3> Digitalization  Solution</h3>
                        <p style={{ textAlign: "left" }}>Our Digitalization Solution transforms your operations with cutting-edge technology, streamlining processes and enhancing efficiency. Experience seamless integration,and future-ready adaptability to drive your business forward.
                        </p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={ds} alt="" style={{ height: "40%", width: "30%", textAlign: "left" }} />
                        </div>
                        <h3>Machine Automation</h3>
                        <p style={{ textAlign: "left" }}>Our Machine Automation solution boosts productivity and precision with cutting-edge technology. Streamline your operations, reduce errors, and achieve unparalleled efficiency, all while ensuring seamless integration and reliable performance.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={ems} alt="" style={{ height: "40%", width: "30%" }} />
                        </div>
                        <h3>Energy Monitoring System</h3>
                        <p style={{ textAlign: "left" }}>Our Energy Monitoring System delivers real-time insights and analytics, optimizing energy use and reducing costs. Enhance efficiency, ensure sustainability, and make informed decisions with our advanced, user-friendly solution.</p>
                    </div>
                </div>

                <div className="services-cards">
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={cm}
                                alt="" style={{ height: "40%", width: "40%" }} />
                        </div>
                        <h3> Condition Monitoring</h3>
                        <p style={{ textAlign: "left" }}>Our Condition Monitoring solution offers real-time insights and predictive analytics to ensure peak performance and prevent downtime. Optimize maintenance, enhance reliability, and extend equipment life with our advanced monitoring technology.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon" style={{ minWidthwidth: "300px" }}>
                            {/* Add your icon here */}
                        </div>
                        <h3 style={{ color: "#4184b0", }}>Our Service Provide</h3><br />
                        <p style={{ textAlign: "left" }}>
                            <li>process control Automation</li><br />
                            <li>IIoT Solution</li><br />
                            <li> Industrial Networking </li><br />
                            <li>Digitalization  Solution</li><br />
                            <li>Machine Automation</li><br />
                            <li>Energy Monitoring System</li><br />
                            <li>Condition Monitoring</li><br />
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Service;
