import React from 'react'
import iot1 from "../Image/iot1.svg"
import iiot1 from "../Image/IOT.png"
import iio2 from "../Image/iot2.png.jpeg"
import iio3 from "../Image/iot3.png.jpeg"
import iio4 from "../Image/iot4.png.jpeg"

const IIoT = () => {
    return (
        <>
            <div className="container">
                <br /><br />
                <div className='row mt-5'>
                    <h2 style={{ textAlign: "left", float: "left", fontSize: "1rem" }}>IoT stands for the Internet of Things, a network of interconnected devices embedded with sensors, software, and other technologies to collect and exchange data over the internet. These "things" can range from simple household appliances like smart thermostats and refrigerators to complex industrial machinery.</h2>
                    <div className='col-sm-6 mt-5'>
                        <p>


                            <h2 style={{ textAlign: "left", fontSize: "1rem" }}>Devices:</h2>
                            <h3 style={{ textAlign: "left" }}> Physical objects equipped with sensors, actuators, and communication hardware. Examples include smart home appliances, wearable health monitors, industrial equipment, and vehicles.
                            </h3>
                            <br />
                            <h2 style={{ textAlign: "left", fontSize: "1rem" }}>Connectivity:</h2>
                            <h3 style={{ textAlign: "left" }}> IoT devices communicate with each other and central systems through various connectivity options like Wi-Fi, Bluetooth, cellular networks, and Low Power Wide Area Networks (LPWAN).
                            </h3><br />
                            <h2 style={{ textAlign: "left", fontSize: "1rem" }}>Data Processing:</h2>
                            <h3 style={{ textAlign: "left" }}>Once data is collected, it needs to be processed. This processing can occur on the device itself (edge computing) or on centralized servers (cloud computing).
                            </h3><br />
                            <br />  <h2 style={{ textAlign: "left", fontSize: "1rem" }}>User Interface:</h2>
                            <h3 style={{ textAlign: "left" }}>Provides a way for users to interact with the IoT system, often through mobile apps, web interfaces, or voice-controlled assistants.

                                Represents a section for the "NSD PROCESS," likely handling data or control specific to certain devices or subsystems in the overall network.
                                Includes symbols representing different types of hardware components or sensors.</h3>
                        </p>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <img src={iot1} alt="iot.png" />
                    </div>
                </div>

                <div className='row mt-5'>

                    <div className='col-12 mt-5'>
                        <img src={iio2} alt="iot.png" />
                    </div>

                </div>
                <div className='row mt-5'>

                    <div className='col-12 mt-5'>
                        <img src={iio3} alt="iot.png" />
                    </div>

                </div>
                <div className='row mt-5'>

                    <div className='col-12 mt-5'>
                        <img src={iio4} alt="iot.png" />
                    </div>

                </div>
                <div className='row mt-5'>
                    <h2 style={{ textAlign: "center", fontSize: "1rem" }}>This image appears to be a network or system diagram, likely related to industrial automation, SCADA (Supervisory Control and Data Acquisition), or an IIoT (Industrial Internet of Things) architecture. Here’s a breakdown of its components: </h2>

                    <div className='col-sm-6 mt-5'>
                        <p>
                            <h2 style={{ textAlign: "left", fontSize: "1rem" }}>SCADA Room (Top-Left):</h2>

                            <h3 style={{ textAlign: "left" }}>  Displays several SCADA (Supervisory Control and Data Acquisition) workstations, including different systems like "EMS SCADA" and "NMS SCADA."
                                These workstations are connected to various components, showing how data is monitored and managed.</h3>
                            <br /> <h2 style={{ textAlign: "left", fontSize: "1rem" }}> NSD Process (Middle-Left):</h2>

                            <h3 style={{ textAlign: "left" }}>  Represents a section for the "NSD PROCESS," likely handling data or control specific to certain devices or subsystems in the overall network.
                                Includes symbols representing different types of hardware components or sensors.</h3>
                            <br /> <h2 style={{ textAlign: "left", fontSize: "1rem" }}> NMB Process (Top-Right):</h2>

                            <h3 style={{ textAlign: "left" }}> Shows another set of processes, "NMB PROCESS," which may deal with a different aspect of data handling or device management.
                                Contains various interconnected symbols, suggesting complex interactions between hardware elements.</h3>
                        </p>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <img src={iiot1} alt="iot.png" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default IIoT