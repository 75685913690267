import React from 'react';
import "../styles/CardDefaut.css"
import san2 from "../Image/san2.jpg"
import san1 from "../Image/san1.jpg"
import san3 from "../Image/san3.jpg"
import san4 from "../Image/san4.jpg"
import san9 from "../Image/san9.jpg"

import san11 from "../Image/san11.jpg"


const TicketCard = () => {
    const tickets = [
        {
          id: 1,
          background: san1,
          title: 'Industrial automation refers to using control systems, such as computers or robots, and information technologies to handle different processes and machinery in industries, replacing human intervention. It is an essential aspect of modern manufacturing and production systems, aiming to improve efficiency, quality, safety, and cost-effectiveness.',
          head:"Industrial Automation "
          // subtitle: 'Et moi un je suis sous-titre',
          // price: 'De 0 à 15 €',
          // info: 'Parc des expositions à NANTES',
          // date: 'Samedi 1er février 2020',
         
        },
        {
          id: 2,
          background:  san2,
          title: 'We offer Industrial Internet of Things (IIoT) solutions, connecting devices and systems across industries. Our IIoT solutions enable real-time data collection and analysis, driving operational efficiency and informed decision-making.',
          head:"IIoT Solutions"    
        },
        {
          id: 3,
          background: san3,
          title: ' Automate manual processes, reduce errors, and save time with seamless digital workflows designed to boost productivity and enhance efficiency. Our solution integrates effortlessly with existing systems, providing real-time data access and insights for smarter decision-making. Elevate your operations to a new level of agility, security, and innovation.',
          head:"Digitization Solution"

        },
        {
          id: 4,
          background: san4,
          title: 'We provide robust and secure industrial networking solutions, ensuring seamless communication between various industrial systems and devices. Our networking solutions are designed to withstand the demanding conditions of industrial environments.',
           head : "Industrial Networking" 
        },
        {
          id: 5,
          background: san11,
          title: 'We offer sensors and process instrumentation solutions for industrial processes. These devices gather real-time data, enabling optimization of operations, enhanced safety, and reduced downtime.',
         head : "Sensors and Process Instrumentation"
          // subtitle: 'Et moi un je suis sous-titre',
          // price: 'De 0 à 15 €',
          // info: 'Parc des expositions à NANTES',
          // date: 'Samedi 1er février 2020',
          // disclaimer: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi eveniet perferendis culpa. Expedita architecto nesciunt, rem distinctio',
        },
        {
          id: 6,
          background: san9,
          title: 'Transform your energy usage with our cutting-edge Energy Monitoring System. Track consumption in real-time, optimize efficiency, and reduce costs—all from a user-friendly dashboard. Make data-driven decisions and achieve sustainable energy savings today!',
         head:"Dashboard"

          // subtitle: 'Et moi un je suis sous-titre',
          // price: 'De 0 à 15 €',
          // info: 'Parc des expositions à NANTES',
          // date: 'Samedi 1er février 2020',
          // disclaimer: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi eveniet perferendis culpa. Expedita architecto nesciunt, rem distinctio',
        },
        
        // Add more tickets here...
      ];
    return (
        <ul className="ticket-list">
            {tickets.map(ticket => (
                <li
                    key={ticket.id}
                    className="booking-card"
                    style={{ backgroundImage: `url(${ticket.background})` }}
                >
                    <div className="book-container">
                        <div className="content">
                            <button className="btn"> { ticket.head}</button>
                        </div>
                    </div>
                     <div className="informations-container">
                        <h2 className="title">{ticket.title}</h2>
                        {/* <p className="sub-title">{ticket.subtitle}</p>
                        <p className="price">{ticket.price}</p>
                        <div className="more-information">
                            <div className="info-and-date-container">
                                 <div className="box info">
                                    <p>{ticket.info}</p>
                                </div>
                                <div className="box date">
                                    <p>{ticket.date}</p>
                                </div> 
                            </div> */}
                            <p className="disclaimer">{ticket.disclaimer}</p>
                        {/* </div> */}
                    </div> 
                </li>
            ))}
        </ul>
    );
};

export default TicketCard;
