// src/HeroSection.js
import React from 'react';
// import image from './path-to-your-image.jpg'; // Add the path to the image here
import service from './Image/service.jpeg'
import "./styles/Deals.css"
// import vfd from "./Icons/vfd.png"
import cpnl from "./Icons/cpnl.png"
import cvalve from "./Icons/cvalve.jpeg"
import ds1 from "./Icons/ds1.png"
import ds from "./Icons/ds.png"
import ems from "./Icons/ems.png"
import cm from "./Icons/cm.png"
import hmiImage from "./Image/HmiImage.jpg"
// import vfd from "./Icons/vfd.png"
import spp from "./Image/spp.jpg"
import CVV from './Image/CVV1.webp'
import smil from './Image/smil.jpg'
import plc1 from './Image/plc1.png'
import vfd from './Image/vfd.jpg'
import scada from './Image/scada.webp'

const Deals = () => {
    return (
        <>
            <div className="services-section">
                <div data-aos="flip-left">
                    <br /><br></br>
                    <h2 style={{ textAlign: "center", color: "#4184b0", marginTop: "15px",fontSize:"2rem" }}> We Deals In</h2><br/>
                </div>

                <p style={{ color: 'black', }}>Expertly delivering DSC, PLC, SCADA, VFD, and control valve solutions for optimized instrument control in sugar plants. Maximize efficiency and reliability with our advanced automation expertise.</p>
            </div>

            <div className="deals-content">
                <br />
                <h2 className="deals-title" style={{ color: "#4184b0", }}>  DSC, PLC, SCADA AND VFD</h2>
                <p className="deals-subtitle">
                    DSC, PLC, SCADA and VFD are key components in industrial automation.
                </p>


                <div className="deals-section">
                    <div className="deals-text">

                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>

                                <h4 style={{ color: "#4184b0", }}> DSC (Distributed Control System)</h4>
                                <p style={{ color: 'black', }}>
                                    DSC systems manage complex processes, often in large plants, by distributing control across multiple nodes, ensuring reliability and flexibility. They are used in industries like oil and gas, power generation, and chemical processing, where high-level control and monitoring are crucial.
                                </p>

                            </div>
                        </div>
                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>
                                <h4 style={{ color: "#4184b0", }}>PLC (Programmable Logic Controller)</h4>
                                <p style={{ color: 'black', }}>
                                    PLC is a specialized computer used for automation of industrial processes. PLCs are highly reliable, rugged, and capable of real-time control. They are widely used in manufacturing lines, robotics, and machinery control due to their programmability and adaptability to various applications.
                                </p>

                            </div>
                        </div>
                        <div className="deals-feature">
                            <div className="icon software-icon"></div>
                            <div>
                                <h4 style={{ color: "#4184b0", }}>  Robustness and Reliability</h4>
                                <p style={{ color: 'black', }}>Designed for Harsh Environments: PLCs are built to operate in environments with high levels of dust, moisture, heat, vibration, and electrical noise.<br />
                                    Long Life Span: They can run continuously for years without failure, making them suitable for critical industrial applications.
                                </p>                            </div>
                        </div>
                        <div className="deals-feature">
                            <div className="icon infrastructure-icon"></div>

                        </div>
                    </div>
                    <div className="deals-image">
                        <img src={plc1} alt="HMI Dashboard" />

                    </div>


                </div>
                <div className="deals-section">
                    <div className="deals-text">
                        <h4 style={{ color: "#4184b0", fontSize: "1rem" }}>VFD (Variable Frequency Drive) </h4>
                        <p style={{ color: 'black', }}>VFD is an electronic device that controls the speed of an electric motor by varying the frequency and voltage supplied to it. VFDs are crucial for energy efficiency and precise motor control in HVAC systems, pumps, and conveyors.</p>


                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>
                                <br />
                                <h4 style={{ color: "#4184b0", }}> Key Features of VFDs:</h4>
                                <p style={{ color: 'black', }}>


                                    <b >Speed Control: </b>VFDs allow precise control of motor speed, enabling equipment to operate at optimal performance levels for different applications.
                                    <br /><br />
                                    <b> Energy Efficiency:</b> By adjusting the motor's speed to match the load requirements, VFDs reduce energy consumption, especially in applications like fans, pumps, and HVAC systems, where full speed is often unnecessary.
                                    <br /><br />
                                    <b> Soft Start/Stop:</b> VFDs provide smooth acceleration and deceleration of motors, minimizing mechanical stress and extending the lifespan of equipment.
                                    <br /><br />
                                    <b> Torque Control:</b> VFDs can maintain consistent torque across a wide range of speeds, critical for applications like conveyors, mixers, and cranes.
                                    <br /><br />
                                    <b> Protection:</b> VFDs offer motor protection features, including overload, short-circuit, and overvoltage protections.</p>

                            </div>
                        </div>

                        <div className="deals-feature">
                            <div className="icon infrastructure-icon"></div>

                        </div>
                    </div>
                    <div className="deals-image">
                        <img src={scada} alt="HMI Dashboard" />

                    </div>


                </div>
                <div className="deals-section">
                    <div className="deals-text">

                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>

                                <h4 style={{ color: "#4184b0", }}> SCADA (Supervisory Control and Data Acquisition)</h4>
                                <p style={{ color: 'black', }}>
                                    SCADA (Supervisory Control and Data Acquisition) is a system used for real-time monitoring, control, and data collection in industrial environments. It allows operators to manage and control various industrial processes by providing a centralized platform for observing system performance, analyzing data, and controlling operations remotely. Here are the key components, features, and benefits of SCADA:</p>



                            </div>
                        </div>
                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>
                                <h4 style={{ color: "#4184b0", }}>Key Components of SCADA</h4>
                                <p style={{ color: 'black', }}>
                                    The HMI is the user interface of the SCADA system, where operators can monitor processes in real-time. It displays data graphically, allowing users to visualize parameters like temperature, pressure, and flow rates</p>

                            </div>
                        </div>
                        <div className="deals-feature">
                            <div className="icon software-icon"></div>
                            <div>
                                <h4 style={{ color: "#4184b0", }}> SCADA (Supervisory Control and Data Acquisition)</h4>
                                <p style={{ color: 'black', }}>SCADA is a software system that allows operators to monitor and control industrial processes in real-time from a central location. SCADA systems are essential in industries like water treatment, energy distribution, and manufacturing, providing a comprehensive overview and enabling quick response to any issues.</p>                            </div>
                        </div>
                        
                    </div>
                    <div className="deals-image">
                       
                        <img src={vfd} alt="HMI Dashboard" />

                    </div>


                </div>
            </div>
            <br /><br />
            <div className="deals-content">
                <br />
                <h2 className="deals-title" style={{ color: "#4184b0", }}>  Control valve</h2>
                <p className="deals-subtitle">
                    A control valve is a device used to regulate the flow of fluids (liquids, gases, or slurries) in a system.
                </p>


                <div className="deals-section">
                    <div className="deals-text">

                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>

                                <h4 style={{ color: "#4184b0", }}>  Control Valve:</h4>
                                <p style={{ color: 'black', }}>

                                    It is an essential component in various industrial processes, such as manufacturing, oil and gas, water treatment, chemical processing, and power generation. By adjusting the flow of fluids, control valves help maintain desired levels of pressure, temperature, and fluid levels, ensuring that a process operates efficiently and safely.
                                </p>
                            </div>
                        </div>
                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>
                                <h4 style={{ color: "#4184b0", }}>How Control Valves Work</h4>
                                <p style={{ color: 'black', }}>
                                    Control valves work by varying the size of the flow passage, which allows for the precise control of fluid flow rate. This is usually achieved through the movement of a valve plug or disc, which is connected to an actuator. The actuator receives a control signal (usually from a process controller) and moves the valve's internal components to open, close, or modulate the flow.</p>

                            </div>
                        </div>
                        <div className="deals-feature">
                            <div className="icon software-icon"></div>
                            <div>
                                <h4 style={{ color: "#4184b0", }}> Benefits of Control Valves</h4>
                                <p style={{ color: 'black', }}>
                                    Precise Flow Control: Allows for accurate adjustment of flow rates, ensuring optimal process conditions.<br />
                                    Safety: Helps maintain safe operating conditions by regulating pressure and preventing system overloads.<br />
                                    Energy Efficiency: Optimizes fluid dynamics to reduce energy consumption and operational costs.<br />
                                    Flexibility: Can be used in various applications, including highly specialized and critical operations.</p>                            </div>
                        </div>
                        {/* <div className="deals-feature">
                            <div className="icon infrastructure-icon"></div>
                            <div>
                                <h4 style={{ color: "#4184b0", }}>VFD (Variable Frequency Drive) </h4>
                                <p style={{ color: 'black', }}>VFD is an electronic device that controls the speed of an electric motor by varying the frequency and voltage supplied to it. VFDs are crucial for energy efficiency and precise motor control in HVAC systems, pumps, and conveyors.</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="deals-image">
                        <img src={CVV} alt="HMI Dashboard" />

                    </div>


                </div>
            </div>
            <br /><br />


            {/* <div className="services-cards">
                <div data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000">


                    <div className="service-card">
                        <div className="service-icon">
                            <img src={vfd} alt="" style={{ height: "50%", width: "50%" }} />
                        </div>

                        <h3>Variable Frequency Drive </h3>

                        <p style={{ textAlign: 'inherit' }}>VFD enhances energy efficiency, leading to significant cost savings. Emphasize its ability to extend motor life, reduce maintenance costs, and improve process control.
                        </p>
                    </div>
                </div>
                <div className="service-card">
                    <div className="service-icon">
                        <img src={cpnl} alt="" style={{ height: "50%", width: "40%" }} />
                    </div>
                    <h3>Control panel</h3>
                    <p style={{ textAlign: "left" }}> A control panel ensures seamless operation by integrating all essential controls in one place, enhancing safety, efficiency, and reliability. It simplifies system management, reducing downtime and boosting productivity.</p>
                </div>
                <div className="service-card">
                    <div className="service-icon">
                        <img src={cvalve} alt="" style={{ height: "40%", width: "40%" }} />
                    </div>
                    <h3 style={{ textAlign: "left" }}>control valve </h3>
                    <p style={{ textAlign: "left" }}>A control valve is a device used in various industrial processes to control the flow of fluids (such as gas, steam, water, or chemical compounds) by varying the size of the flow passage</p>
                </div>
            </div> */}
            <br /><br /><br /><br />

            {/* <div className="services-cards">
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={ds1} alt="" style={{ height: "40%", width: "30%" }} />
                        </div>
                        <h3> Digitalization  Solution</h3>
                        <p style={{ textAlign: "left" }}>Our Digitalization Solution transforms your operations with cutting-edge technology, streamlining processes and enhancing efficiency. Experience seamless integration,and future-ready adaptability to drive your business forward.
                        </p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={ds} alt="" style={{ height: "40%", width: "30%", textAlign: "left" }} />
                        </div>
                        <h3>Machine Automation</h3>
                        <p style={{ textAlign: "left" }}>Our Machine Automation solution boosts productivity and precision with cutting-edge technology. Streamline your operations, reduce errors, and achieve unparalleled efficiency, all while ensuring seamless integration and reliable performance.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={ems} alt="" style={{ height: "40%", width: "30%" }} />
                        </div>
                        <h3>Energy Monitoring System</h3>
                        <p style={{ textAlign: "left" }}>Our Energy Monitoring System delivers real-time insights and analytics, optimizing energy use and reducing costs. Enhance efficiency, ensure sustainability, and make informed decisions with our advanced, user-friendly solution.</p>
                    </div>
                </div> */}

            {/* <div className="services-cards">
                    <div className="service-card">
                        <div className="service-icon">
                            <img src={cm}
                                alt="" style={{ height: "40%", width: "40%" }} />
                        </div>
                        <h3> Condition Monitoring</h3>
                        <p style={{ textAlign: "left" }}>Our Condition Monitoring solution offers real-time insights and predictive analytics to ensure peak performance and prevent downtime. Optimize maintenance, enhance reliability, and extend equipment life with our advanced monitoring technology.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon" style={{ minWidthwidth: "300px" }}>
                          
                        </div>
                        <h3 style={{ color: "#4184b0", }}>Our Service Provide</h3><br />
                        <p style={{ textAlign: "left" }}>
                            <li>process control Automation</li><br />
                            <li>IIoT Solution</li><br />
                            <li> Industrial Networking </li><br />
                            <li>Digitalization  Solution</li><br />
                            <li>Machine Automation</li><br />
                            <li>Energy Monitoring System</li><br />
                            <li>Condition Monitoring</li><br />
                        </p>
                    </div>
                </div> */}
            <div className="deals-content">
                <br />
                <h2 className="deals-title" style={{ color: "#4184b0", }}>Suger plant Instruments</h2>
                <p className="deals-subtitle">
                    Comprehensive Instrumentation for Sugar Plants: Boosting Efficiency and Quality
                </p>

                <div className="deals-section">
                    <div className="deals-text">

                        <div className="deals-feature">
                            <div className="icon hardware-icon"></div>
                            <div>
                                <br />
                                <h4 style={{ color: "#4184b0", }}>  Flow Measurement Instruments</h4>
                                <p style={{ color: 'black', }}>
                                    Flow meters are crucial in a sugar plant for measuring the flow rate of various liquids and gases, such as sugarcane juice, water, steam, and chemicals. Accurate flow measurement is essential for ensuring optimal use of resources and maintaining a balanced production process. Common types of flow meters used in sugar plants include electromagnetic, ultrasonic, and vortex flow meters. These instruments provide precise, reliable measurements, allowing operators to control process variables, reduce energy consumption, and avoid material wastage.
                                </p>
                                <br /><br />
                                <h4 style={{ color: "#4184b0", }}> Pressure Measurement Instruments</h4>
                                <p style={{ color: 'black', }}>
                                    Pressure transmitters and gauges are essential for monitoring the pressure of fluids, steam, and gases in various stages of sugar production. Accurate pressure measurement helps ensure that the process conditions remain within safe and efficient operating limits. For example, maintaining the correct pressure in evaporators, boilers, and vacuum pans is critical for optimizing evaporation rates, reducing energy consumption, and achieving the desired concentration of sugar. Differential pressure transmitters, diaphragm seals, and pressure gauges are often used to provide reliable and precise pressure readings.</p>


                            </div>
                        </div>
                        {/* <div className="deals-feature">
                                    <div className="icon hardware-icon"></div>
                                    <div>
                                        <h4 style={{  color: "#4184b0", }}>PLC (Programmable Logic Controller)</h4>
                                        <p style={{  color: 'black', }}>
                                            PLC is a specialized computer used for automation of industrial processes. PLCs are highly reliable, rugged, and capable of real-time control. They are widely used in manufacturing lines, robotics, and machinery control due to their programmability and adaptability to various applications.
                                        </p>

                                    </div>
                                </div> */}
                        {/* <div className="deals-feature">
                                    <div className="icon software-icon"></div>
                                    <div>
                                        <h4 style={{  color: "#4184b0", }}> SCADA (Supervisory Control and Data Acquisition)</h4>
                                        <p style={{  color: 'black', }}>SCADA is a software system that allows operators to monitor and control industrial processes in real-time from a central location. SCADA systems are essential in industries like water treatment, energy distribution, and manufacturing, providing a comprehensive overview and enabling quick response to any issues.</p>                            </div>
                                </div> */}
                        {/* <div className="deals-feature">
                                    <div className="icon infrastructure-icon"></div>
                                    <div>
                                        <h4 style={{  color: "#4184b0", }}>VFD (Variable Frequency Drive) </h4>
                                        <p style={{  color: 'black', }}>VFD is an electronic device that controls the speed of an electric motor by varying the frequency and voltage supplied to it. VFDs are crucial for energy efficiency and precise motor control in HVAC systems, pumps, and conveyors.</p>
                                    </div>
                                </div> */}
                    </div>
                    <div className="deals-image">
                        <img src={smil} alt="HMI Dashboard" />

                    </div>


                </div>
            </div>

        </>
    );
}

export default Deals;
