import React from 'react';
import './styles/Ins.css'; // Import the CSS file for styling
import pre1 from "./Image/pre1.jpg"
import pre2 from "./Image/pre2.jpg"
import pre3 from "./Image/pre3.jpg"
import pre4 from "./Image/pre4.jpg"
import fi1 from "./Image/fi1.jpg"
import fi2 from "./Image/fi2.jpg"
import fi3 from "./Image/fi3.jpg"
import fi4 from "./Image/fi4.jpg"
import fi5 from "./Image/fi5.jpg"
import fi6 from "./Image/fi6.jpg"
import fi7 from "./Image/fi7.jpg"
import fi8 from "./Image/fi8.jpg"
import fi9 from "./Image/fi9.jpg"
import fi10 from "./Image/fi10.jpg"
import fi11 from "./Image/fi11.jpg"
import fi12 from "./Image/fi12.jpg"
import tem1 from "./Image/tem1.jpg"
import tem2 from "./Image/tem2.jpg"
import tem3 from "./Image/tem3.jpg"
import tem4 from "./Image/tem4.jpg"
import tem5 from "./Image/tem5.jpg"
import tem6 from "./Image/tem6.jpg"

import la1 from "./Image/la1.jpg"
import la2 from "./Image/la2.jpg"
import la3 from "./Image/la3.jpg"
import la4 from "./Image/la4.jpg"
import la5 from "./Image/la5.jpg"
import la6 from "./Image/la6.jpg"
import la7 from "./Image/la7.jpg"
import la8 from "./Image/la8.jpg"
import la9 from "./Image/la9.jpg"
import './styles/styles.css';




const Ins = () => {
    return (
        <> <br /><br />

            <div className="ins-container"><br/><br/>
            <p style={{ textAlign: "center", color: "#4184b0", fontSize: "3rem" }}>Field Instrument</p>
            <br/><br/>
                <p style={{ textAlign: "center", color: "#4184b0", fontSize: "2rem" }}>Pressure</p>

                <p>Process and differential pressure measurement in acids, sludges, gases or vapors</p><br/><br/>

                <div className="ins-grid">
                    <div className="ins-card">
                        <img src={pre1} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Transducer</h3>
                        <p style={{ color: "black", }}> A compact pressure transducer with preset measuring range. The Cerabar product family offers robust ceramic sensors up to 40 bar or metal sensors up to 400 bar for absolute and overpressure measurements.</p>
                    </div>

                    <div className="ins-card">
                        <img src={pre2} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Pressure Switch</h3>
                        <p style={{ color: "black", }}>For safe measurement and monitoring of absolute pressure and overpressure in gases, vapors and fluids. Smooth operation with display and on-site operation as well as a modular adapter system for easy connection to all processes.</p>
                    </div>

                    <div className="ins-card">
                        <img src={pre3} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Analog and Digital Transmitters
                        </h3>
                        <p style={{ color: "black", }}>Flexible device platform for universal application in your processes. This includes FDA-compliant materials and aseptic connections that are especially suitable for hygienic applications.</p>
                    </div>



                    <div className="ins-card">
                        <img src={pre4} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Digital transmitters</h3>
                        <p style={{ color: "black", }}>These high-end pressure transmitters offer you a comprehensive safety package . Reliable data management is provided in the form of HistoROM. The digital transmitters are developed, constructed and manufactured in compliance up to SIL 3/IEC 61508</p>
                    </div>
                </div>

            </div>
            <div className="ins-container">
                <h2 style={{ textAlign: "center", color: "#4184b0", fontSize: "2rem" }}>Level</h2>
                <p style={{ color: "black", }}>Continuous level measurement and point level detection in fluids and bulk solids</p>
                {/* <h1>level</h1> */}
                <div className="ins-grid">
                    <div className="ins-card">
                        <img src={fi1} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Radar</h3>
                        <p style={{ color: "black", }}>Continuous non-contact Time of Flight measurement in fluids and bulk solids, even under extreme conditions such as changes of medium, gas formation, vapor, foams. Temperature up to 450°C; pressure up to 160 bar.</p>
                    </div>

                    <div className="ins-card">
                        <img src={fi2} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Ultrasonic</h3>
                        <p style={{ color: "black", }}>Continuous non-contact Time of Flight measurement in fluids and bulk solids. Independent of product properties such as density or dielectric constant. Temperatures up to 80°C, pressures up to 4 bar.</p>
                    </div>

                    <div className="ins-card">
                        <img src={fi3} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Guided Radar</h3>
                        <p style={{ color: "black", }}>Continuous Time of Flight measurement in fluids and bulk solids. Independent of product properties such as density or dielectric constant. Temperatures up to 450°C, pressures up to 400 bar.</p>
                    </div>

                    {/* Add more ins-card items here based on your needs */}

                    <div className="ins-card">
                        <img src={fi4} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Radiometry</h3>
                        <p style={{ color: "black", }}>Experience non-contact level measurement with precision radiometry. Perfect for extreme conditions, it reliably measures levels in challenging environments like high temperatures, dense fog, or vapor-filled spaces, ensuring continuous, accurate performance.</p>
                    </div>
                </div>
                <br /><br /><br />
                <div className="ins-grid">
                    <div className="ins-card">
                        <img src={fi5} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Vibronics For fluid</h3>
                        <p style={{ color: "black", }}>Point level switch for all fluids even in the presence of build-up, turbulence or air bubbles. Independent of the electrical properties of the medium. Temperatures up to 280 °C; pressures up to 100 bar.</p>
                    </div>

                    <div className="ins-card">
                        <img src={fi6} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Vibronics for solids</h3>
                        <p style={{ color: "black", }}>Point level detection in all kinds of bulk solids up to a maximum grain size of approx. 10 mm. Calibrationfree, maintenance-free. Temperatures up to 280 °C, pressures up to 25 bar.</p>
                    </div>

                    <div className="ins-card">
                        <img src={fi7} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Hydrostatic</h3>
                        <p style={{ color: "black", }}>Level optimized pressure sensor cell for measurement in fluids, pastes and sludges. Independent of foam formation and changing product properties. Temperatures up to 400 °C; pressures up to 40 bar.</p>
                    </div>

                    {/* Add more ins-card items here based on your needs */}

                    <div className="ins-card">
                        <img src={fi8} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Differential Pressure</h3>
                        <p style={{ color: "black", }}>Level measurement in closed, pressurized vessels. Not affected by dielectric constant, foam, turbulences or obstacles. Temperatures up to 400 °C; pressures up to 420 bar.</p>
                    </div>
                </div>
                <br /><br /><br />
                <div className="ins-grid">
                    <div className="ins-card">
                        <img src={fi9} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Capacitance
                        </h3>
                        <p style={{ color: "black", }}>Point level detection and continuous level measurement in fluids and bulk solids. Even with aggressive media and heavy

                        </p>
                    </div>

                    <div className="ins-card">
                        <img src={fi10} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Conductive</h3>
                        <p style={{ color: "black", }}>Easy, cost-effective level limit detection in conductive fluids such as water, wastewater, liquid foodstuffs etc.</p>
                    </div>

                    <div className="ins-card">
                        <img src={fi11} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Paddle Switch</h3>
                        Low-cost point level switch for bulk solids of all kinds up to a grain size of 50 mm, solid weight &lt; 100 g/I. Temperatures up to 80 °C,
                    </div>

                    {/* Add more ins-card items here based on your needs */}

                    <div className="ins-card">
                        <img src={fi12} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Electromechanical level system</h3>
                        <p style={{ color: "black", }}>Robust, mechanical system for measurement in bulk solids for applications in high vessels (up to 70 m).</p>
                    </div>
                </div>
                {/* <h1>  tempratre page</h1> */}
                <div>
                    <br />
                    <br />
                    <p style={{ textAlign: "center", color: "#4184b0", fontSize: "2rem" }}> Temperature</p>
                    <br />
                    <p>Temperature in Automation typically refers to the use of automated systems to monitor, control, and maintain temperature levels in various environments, such as industrial processes, smart homes, data centers, or agricultural settings. </p><br /><br />
                </div>
                <div className="ins-grid">
                    <div className="ins-card">
                        <img src={tem1} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Head transmitters</h3>
                        <p style={{ color: "black", }}>Head transmitters offer precise temperature measurements directly from sensors, ensuring accurate data, reduced wiring costs, and easy integration into existing systems for efficient, reliable automation control</p>
                    </div>

                    <div className="ins-card">
                        <img src={tem2} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>DIN rail transmitters</h3>
                        <p style={{ color: "black", }}>DIN Rail Transmitters are compact devices that convert sensor signals into standardized outputs  They are mounted on DIN rails for easy installation in control panels, providing accurate signal transmission</p>
                    </div>

                    <div className="ins-card">
                        <img src={tem3} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Field transmitters</h3>
                        <p style={{ color: "black", }}>Field transmitters provide accurate, real-time data from remote locations, ensuring optimal process control. They are robust, weather-resistant, and  enhancing operational efficiency and reliability</p>
                    </div>

                    {/* Add more ins-card items here based on your needs */}

                    <div className="ins-card">
                        <img src={tem4} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Resistance thermometers</h3>
                        <p style={{ color: "black", }}>Resistance thermometers offer precise and stable temperature measurements, ideal for critical applications. With high accuracy, durability, and fast response times, they ensure optimal performance and reliability in every environment</p>
                    </div>
                </div>
                <br /><br />
                <div className="ins-grid">
                    <div className="ins-card">
                        <img src={tem5} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Thermocouples</h3>
                        <p style={{ color: "black", }}>Thermocouples provide fast, reliable temperature measurements across a wide range. Their durability, cost-effectiveness, and adaptability to extreme conditions make them essential for efficient and accurate process control.</p>
                    </div>

                    <div className="ins-card">
                        <img src={tem6} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Temperature switches</h3>
                        <p style={{ color: "black", }}>Temperature switches offer dependable monitoring and control, activating or deactivating systems automatically at set points. They ensure safety, efficiency, and protection in various industrial and HVAC applications.</p>
                    </div>

                    {/* <div className="ins-card">
                        <img src="guided-radar.png" alt="Guided Radar" />
                        <h3>Guided Radar</h3>
                        <p>Continuous Time of Flight measurement in fluids and bulk solids. Independent of product properties such as density or dielectric constant. Temperatures up to 450°C, pressures up to 400 bar.</p>
                    </div> */}

                    {/* Add more ins-card items here based on your needs */}

                </div>
                <br /><br />
                {/* <h1>leval</h1> */}
                <p style={{ textAlign: "center", color: "#4184b0", fontSize: "2rem" }}> Liquid analysis</p>
                <br />
                <p>Comprehensive product range for all analytical parameters</p>
                <br/><br/>
                <div className="ins-grid">
                    <div className="ins-card">
                        <img src={la1} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>pH/ORP</h3>
                        <p style={{ color: "black", }}>Glass and glass-free Memosens sensors, transmitters and assemblies for standard, hygienic and hazardous applications; fully automated measuring, cleaning and calibration systems.</p> </div>

                    <div className="ins-card">
                        <img src={la2} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Conductivity</h3>
                        <p style={{ color: "black", }}>Conductive and toroidal Memosens sensors and transmitters for all measuring compact measuring devices, calibration and verification systems.</p>
                    </div>



                    {/* Add more ins-card items here based on your needs */}

                    <div className="ins-card">
                        <img src={la3} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Turbidity/solids
                        </h3>
                        <p style={{ color: "black", }}>Optical Memosens sensors and transmitters for all measuring ranges from lowest turbidity in drinking water to solids in wastewater applications; ultrasonic sludge level measurement.</p>
                    </div>
                    <div className="ins-card">
                        <img src={la4} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Oxygen
                        </h3>
                        <p>Amperometric and optical Memosens sensors, transmitters and assemblies for all applications, including hazardous areas, hygienic processes and trace measurement.</p>
                    </div>
                    <div className="ins-card">
                        <img src={la5} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Disinfection (chlorine)
                        </h3>
                        <p style={{ color: "black", }}>Amperometric Memosens sensors for water treatment and swimming pools; flow assembly for simultaneous measurement of chlorine and pH/ORP.</p>
                    </div>
                    <div className="ins-card">
                        <img src={la6} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Analyzers
                        </h3>
                        <p style={{ color: "black", }}>Analyzers deliver precise, real-time data for critical processes, ensuring quality control, safety, and compliance. With advanced technology and easy integration.</p>
                    </div>

                    <div className="ins-card">
                        <img src={la7} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Transmitters
                        </h3>
                        <p style={{ color: "black", }}>Liquiline transmitters featuring all common fieldbuses; suitable for all applications including hazardous areas and hygienic processes; multichannel and multiparameter devices for field or DIN rail installation.</p>
                    </div>
                    <div className="ins-card">
                        <img src={la8} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Process photometers
                        </h3>
                        <p style={{ color: "black", }}>Process photometers for accurate concentration measurement by determining UV absorption, NIR absorption, color, turbidity and cell growth; suitable for hygienic applications and hazardous areas.</p>
                    </div>
                    <div className="ins-card">
                        <img src={la9} alt="" style={{ height: "50%", width: "100%" }} />
                        <h3>Samplers
                        </h3>
                        <p style={{ color: "black", }}>Portable and stationary samplers with Memosens technology for automatic sampling, defined distribution and safe preservation of liquid samples.
                        </p>
                    </div>
                    

                    
                    



                </div>
                
            </div>




        </>
    );
}

export default Ins;
