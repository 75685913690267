import React from 'react';
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './Components/Header/Header.js';
import Home from './Components/Home/Home.jsx';
import ContactForm from './Components/Home/ContactForm.js';
import Footer from './Components/Footer.js';
import About from './Components/about/About.js';
import ScrollToTop from './Components/ScrollToTop.jsx';
import Register from './Components/Home/Register.jsx';
import Service from './Components/Service.jsx';
import Deals from './Components/Deals.js';

import Ins from './Components/Ins.js';
// import Broucher from './Components/Broucher.jsx';
import Event from './Components/Event.jsx';
import PdfViewerPage from './Components/PDF/PdfViewerPage.jsx';
import WhatsAppButton from './Components/whatsAPP/WhatsAppButton.jsx';
import IIoT from './Components/IIoT/IIoT.js';



function App() {
  return (

    <Router>
      <ScrollToTop />
      <Header />
      <WhatsAppButton/>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Ins" element={<Ins />} />

        <Route path="/ContactForm" element={<ContactForm />} />
        <Route path="/about" element={<About />} />
        <Route path="/Deals" element={<Deals />} />
        
        <Route path="/Service" element={<Service />} />
        <Route path="/event" element={<Event />} />
        <Route path="/broucher" element={<PdfViewerPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/IIOT" element={<IIoT />} />


      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
