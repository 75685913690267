import React, { useState, useEffect } from "react";

function Counter({ end, duration, startCounting }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (startCounting) {
      let start = 0;
      const increment = end / (duration / 1000) / 60;

      const timer = setInterval(() => {
        start += increment;
        if (start >= end) {
          start = end;
          clearInterval(timer);
        }
        setCount(Math.round(start));
      }, 1000 / 60); // Update the count 60 times per second
    }
  }, [startCounting, end, duration]);

  return <>{count}+</>;
}

export default Counter;
