import React, { useState, useEffect, useRef } from 'react';
import "../styles/Css.css";
import { Link } from 'react-router-dom';
import logo from "../Image/logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const menuIconRef = useRef(null);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // State to manage hover effect
    const [hoveredLink, setHoveredLink] = useState(null);

    // Dynamic styles for the Link components
    const linkStyle = {
        padding: '15px',
        borderRadius: '7px',
        fontSize: '15px',
        fontWeight: 'bold',
        textDecoration: 'none', // Optional, to remove underline
    };

    return (
        <header className="header-container ae bb bc bd be bf bg bh bi b2 bj">
            <div className="header-background ae bk bg bl bh bi bm bn bo"></div>
            <div className="header-content ae bp bq br bs ag bt ai bu bv bw bx by bz">
                <div className="logo-container ae aw">
                    <img src={logo} alt="logo" className="c0 c1" />
                </div>
                <div className="menu-icon-container ae ag c2 aj" ref={menuIconRef} onClick={() => setMenuOpen(!menuOpen)}>
                    <div className={`ae c3 c4 c5 c6 b2 menu-bar ${menuOpen ? 'open' : ''}`}></div>
                    <div className={`ae c3 c4 c5 c6 b2 menu-bar ${menuOpen ? 'open' : ''}`}></div>
                    <div className={`ae c3 c4 c5 c6 b2 menu-bar ${menuOpen ? 'open' : ''}`}></div>
                </div>
                <nav className={`nav-links ${menuOpen ? 'active' : ''}`} ref={menuRef}>
                    <Link
                        className="nav-link cp cq cr an aw cs b0 ct cu cv cw"
                        to="/"
                        onClick={() => setMenuOpen(false)}
                        style={{
                            ...linkStyle,
                            color: hoveredLink === 'home' ? '#137DC5' : 'black'
                        }}
                        onMouseEnter={() => setHoveredLink('home')}
                        onMouseLeave={() => setHoveredLink(null)}
                    >
                        HOME
                    </Link>

                    <DropdownButton id="dropdown-basic-button" title="PRODUCTS" className="nav-link cp cq cr an aw cs b0 ct cu cv cw">
                        <Link to="/ins" onClick={() => setMenuOpen(false)}>
                            <Dropdown.Item href="#/action-1">INSTRUMENT</Dropdown.Item>
                        </Link>
                        <Link to="/Service" onClick={() => setMenuOpen(false)}>
                            <Dropdown.Item href="#/action-1">SERVICE</Dropdown.Item>
                        </Link>
                        <Link to="/Deals" onClick={() => setMenuOpen(false)}>
                            <Dropdown.Item href="#/action-1">DEALS</Dropdown.Item>
                        </Link>
                        <Link to="/IIoT" onClick={() => setMenuOpen(false)}>
                            <Dropdown.Item href="#/action-1">IIoT</Dropdown.Item>
                        </Link>
                    </DropdownButton>

                    <DropdownButton id="dropdown-basic-button" title="RESOURCE" className="nav-link cp cq cr an aw cs b0 ct cu cv cw">
                        <Link to="/broucher" onClick={() => setMenuOpen(false)}>
                            <Dropdown.Item href="#/action-1">BROUCHER</Dropdown.Item>
                        </Link>
                    </DropdownButton>

                    <Link
                        className="nav-link cp cq cr an aw cs b0 ct cu cv cw"
                        to="/about"
                        onClick={() => setMenuOpen(false)}
                        style={{
                            ...linkStyle,
                            color: hoveredLink === 'about' ? '#137DC5' : 'black'
                        }}
                        onMouseEnter={() => setHoveredLink('about')}
                        onMouseLeave={() => setHoveredLink(null)}
                    >
                        ABOUT
                    </Link>

                    <Link
                        className="nav-link cp cq cr an aw cs b0 ct cu cv cw"
                        to="/ContactForm"
                        onClick={() => setMenuOpen(false)}
                        style={{
                            ...linkStyle,
                            color: hoveredLink === 'contact' ? '#137DC5' : 'black'
                        }}
                        onMouseEnter={() => setHoveredLink('contact')}
                        onMouseLeave={() => setHoveredLink(null)}
                    >
                        CONTACT US
                    </Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;
