import React from 'react';
import '../styles/CardImage.css'; // Import the CSS file
import c1 from "../Image/c1.png";
import c2 from "../Image/c2.jpg";
// import c3 from "../Image/allana.webp";
import c4 from "../Image/c4.jpg";
import c5 from "../Image/c5.jpeg";
import c6 from "../Image/c6.jpg";
import vp from "../Image/vp.jpg";
import c12 from "../Image/c12.jpeg";
const CardImg = () => {
  const images = [
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    { src: c1, alt: 'Image 1' },
    { src: c2, alt: 'Image 2' },
    // { src: c3, alt: 'Image 3' },
    { src: c4, alt: 'Image 4' },
    { src: c5, alt: 'Image 5' },
    { src: c6, alt: 'Image 6' },
    { src: vp, alt: 'Image 7' },
    { src: c12, alt: 'Image 8' },
    // Add more images as needed
  ];

  // Duplicate the images to create a seamless infinite scroll
  const allImages = [...images, ...images];

  return (
    <div className="outer-container">
      <div className="inner-container">
        {allImages.map((image, index) => (
          <div className="card" key={index}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardImg;
