import React from 'react'
import { Link } from 'react-router-dom'
import "./styles/Css.css"
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedinIn, FaSquareXTwitter } from 'react-icons/fa6'
import logo from "./Image/logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    return (
        <footer className="ae k7 k8 k9 jn au" >
            <footer className="footer">
                <div className="footer-container" >
                    <div className="footer-section">
                        {/* <img src="logo.png" alt="Bidinn Logo" className="footer-logo" /> */}
                        <img src={logo} alt="atomize design system logo" className="footer-logo" />
                        <p className="footer-description">
                            An Automation Company Specializes In Developing Technologies And Solutions That Automate Repetitive Or Complex Tasks Across Various Industries. The Goal Is To Increase Efficiency,   Improve Overall Productivity.  </p>
                        {/* <p className="footer-contact">
<i className="fas fa-phone-alt"></i> 18002038011
</p> */}
                    </div>
                    <div className="footer-section">
                        <h4>Our Solution</h4>
                        <ul>
                            <li><a href="#"></a>  Process Control Automation</li>
                            <li><a href="#"></a>Industrial network solutions</li>
                            <li><a href="#">Energy Monitering System</a></li>
                            <li><a href="#">IIoT Software Solution</a></li>
                            <li><a href="#">Condition Monitoring</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h4>QUICK LINKS</h4>
                        <ul>
                            <li><a href="/About ">About Us</a></li>
                            <li><a href="/Service ">Services</a></li>
                            <li><a href="/<Deals">Clients</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="/ContactForm">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h4>Our Deal</h4>
                        <ul>
                            <li><a href="/Deals ">DCS PLC SCADA</a></li>
                            <li><a href="/Deals ">VFD</a></li>
                            <li><a href="/Deals ">Control Panel</a></li>
                            <li><a href="/Ins "></a>Field Instruments</li>
                            <li><a href="/IIoT">Software Solution</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h4>Follow Us On</h4>
                        <div className="footer-social-icons">
                            <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                            <a href="#"><i className="fab fa-twitter"></i></a>
                            <a href="#"><i className="fab fa-youtube"></i></a>
                            <a href="#"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                        {/* <div className="footer-it-partner">
<p>IT Partner → <img src="it-partner-logo.png" alt="IT Partner" className="it-partner-logo" /></p>
<p>Bugfix Software Solution & Education</p>
</div> */}
                       <h6>                           Regd Office: A-19,Ground Floor Fiee Complex,Okhla,New Delhi 110020  <br/>
                           Sales Office: C-104, 1st Floor, Sector 65 Noida, U.P 201301
                           </h6>

                        <h6>
                            Contact: +91-9315340709<br />
                            Email: akg@innovexer.com


                        </h6>
                    </div>

                </div>
                <div className="footer-bottom">
                    <p>© 2024 ITPL | All Rights Reserved</p>
                </div>
            </footer>
           
        </footer>
    );
};

export default Footer;