import React from 'react';
import "../styles/about.css";
import aboutimg from "../Image/aboutimg.png";
import mission from "../Image/mission.png";
import team from "../Image/team.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import useIntersectionObserver from '../IntersectionObserver'; // Adjust path as needed

const About = () => {
  const isAboutUsVisible = useIntersectionObserver({ target: '.about-us-container', threshold: 0.1 });
  const isWhoWeAreVisible = useIntersectionObserver({ target: '.who-we-are-container', threshold: 0.1 });
  const isMissionVisible = useIntersectionObserver({ target: '.about-us-container.mission', threshold: 0.1 });

  return (
    <>
      <div className='container'>
        <div className="ae">
          <div className="ae gk gl ag gm">
            <div className="about-us">                        
              <h1
                className={isAboutUsVisible ? "animate-fadeIn" : "hidden"}
                style={{ textAlign: "center", marginTop: "6rem", fontSize: "40px", fontWeight: "800" }}
              >
                About US
              </h1>

              <div className="about-us-container">
                <div className={`about-us-content ${isAboutUsVisible ? "animate-fadeIn" : "hidden"}`}>
                  <p style={{ margin: "1rem", textAlign: "justify" }}>
                    INNOVEXER TECHCRAFT PVT LTD is a pioneering engineering company that specializes in PLC-SCADA-HMI-VFD-SOFTWERE INSTRUMENTATION IT SOLUTION and manufacturing automotive testing equipment.<br />
                    With over 10 years of experience in the industry, the company has established a reputation for providing high-quality products and services that meet national and international standards.<br />
                    We specialize in providing innovative automotive testing solutions tailored to meet the unique needs of our clients.<br />
                    PLC (Programmable Logic Controller) and SCADA (Supervisory Control and Data Acquisition) systems are essential components of modern industrial automation. They play a crucial role in controlling, monitoring, and optimizing various processes in industries such as manufacturing, energy, and water management.<br />
                    An experienced, factory-trained, technically-focused and locally-based sales force to assist customers with automation projects, from conception and specification to completion.<br />
                    Knowledgeable and highly trained Customer Service Consultants.<br />
                  </p>
                </div>
                <div className={`about-us-image ${isAboutUsVisible ? "animate-fadeIn" : "hidden"}`}>
                  <img src={aboutimg} alt="about us" />
                </div>
              </div>

              <div className={`who-we-are-container ${isWhoWeAreVisible ? "animate-slideIn" : "hidden"}`}>
                <div className="who-we-are-image">
                  <img src={team} alt="our team" />
                </div>
                <div className="who-we-are-content">
                  <h2 style={{ textAlign: "justify" }}>Our Team</h2>
                  <p style={{ textAlign: "justify" }}>
                    Our team specializes in PLC-SCADA-HMI-VFD-SOFTWERE INSTRUMENTATION IT SOLUTION and manufacturing automotive testing equipment.<br />
                    Our team works with clients and customers to solve complex challenges. We continue to innovate by leveraging new technologies. Our experts evaluate plans, designs, and deploy the best services and solutions for your business. Our vision is to lead the global automation landscape by continuously innovating and adapting to our customers' evolving needs.
                  </p>
                </div>
              </div>

              <div className={`about-us-container mission ${isMissionVisible ? "animate-fadeIn" : "hidden"}`}>
                <div className="about-us-content">
                  <h2>Our Mission</h2>
                  <p style={{ marginBottom: "1rem" }}>
                    Innovexer TechCraft is a specialist in industrial automation. Our experienced engineers drive innovation and provide custom solutions to boost productivity and efficiency. We integrate advanced automation and IIoT technologies, continually adopting new technologies to ensure optimized solutions for our customers.<br /><br /><br />
                    <h2 style={{textAlign:"left"}}>Our vision</h2><br/><br/>
                    Our vision is to be recognized as experts in industrial automation, expanding our knowledge and shaping the future of the industry through innovation and excellence. Our core values guide us: honesty, consistency, hard work, technical knowledge, and commitment to continuous learning and technology adoption.
                  </p>
                </div>
                <div className={`about-us-image ${isMissionVisible ? "animate-fadeIn" : "hidden"}`}>
                  <img src={mission} alt="our mission" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
