import React from "react";
import "./ContactForm.css";




const ContactForm = () => {
    return (
        <>

            <h2 style={{ textAlign: "center", fontSize: "2rem", fontWeight: "800", marginTop: '6rem' }}> <span style={{ color: "#4184b0" }}>CONTACT US</span></h2>

            <br />

            <div className="contact-container">

                <div className="contact-info"><br />
                    < h2 style={{ textAlign: "center", color: "#4184b0" }}>INNOVEXER TECHCRAFT PVT LTD</h2><hr />


                    <p>If you have question or would like more information on our works, Please complete the form and we’ll aim get back to you with in 24 hours.

                    </p>
                    <p> Office: C-104, 1st Floor, Sector 65 Noida, U.P 201301</p>
                    <p>Regd Office: A-19 Ground floor,Fiee Complex,Okhla,PH-2, NEW Delhi 110020</p><hr />

                    <div className="contact-details">
                        <p>📞 +91-9315240709, +91-7827060983</p><hr />
                        {/* <i class="fa fa-whatsapp" style={{fontSize:"16px",color:"#4184b0"}}>&nbsp;9315240709</i> */}
                        <i class="fa fa-whatsapp" style={{ fontSize: "16px", color: "#4184b0" }}>&nbsp;&nbsp;9315240709</i><hr />
                        <p>For general and other information (For client only): <a href="akg@innovexer.com">akg@innovexer.com</a></p>
                        <p>Companies seeking talent hiring from ITPL: <a href="sales@innovexer.com">sales@innovexer.com</a></p>
                    </div>
                </div>

                <div className="contact-form">
                    <form action="https://formsubmit.co/ask@innovexer.com" method='POST'>

                        <input type="text" name="firstName" placeholder="First Name" required />
                        <input type="text" name="lastName" placeholder="Last Name" required />
                        <input type="text" name="contact" placeholder="Contact" required />
                        <input type="email" name="email" placeholder="Enter email" required />
                        <textarea name="message" placeholder="Enter your message" required></textarea>

                        <div className="recaptcha">
                            {/* This is a placeholder for reCAPTCHA */}
                            <p>I'm not a robot</p>
                        </div>

                        <button type="submit">SUBMIT</button>
                    </form>
                </div>
            </div>





        </>
    );
};

export default ContactForm;
