import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import "../styles/Css.css"
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedinIn, FaSquareXTwitter } from 'react-icons/fa6'
// import SignUpButton from './SignUpButton';
// import Icon from './Icon';


import logo2 from "../Image/logo2.webp"
import imm1 from "../Image/imm1.png"
import cimens from "../Image/cimens.png"
import honey from "../Image/honey.png"
import ava from "../Image/ava.jpeg"
import bb1 from "../Image/bb1.jpg"
import yoko from "../Image/yoko.jpeg"
import hausar from "../Image/hausar.png"
import ch from "../Image/ch.jpeg"
// import CardDefault from './CardDefault'
import "../styles/CardDefaut.css"
import ContactForm from './ContactForm'
import 'bootstrap/dist/css/bootstrap.min.css';
import Counter from './Counter'
import CardImg from '../CardImageOnly/CardImg'
import font4 from '../video/font4.mp4'
import TicketCard from '../Card/TicketCard'



const Home = () => {

    const [isInView, setIsInView] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsInView(true);
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);


    //second section

    const [inView, setInView] = useState({
        span: false,
        p1: false,
        p2: false
    });

    const spanRef = useRef(null);
    const p1Ref = useRef(null);
    const p2Ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (entry.target === spanRef.current) {
                            setInView(prev => ({ ...prev, span: true }));
                        } else if (entry.target === p1Ref.current) {
                            setInView(prev => ({ ...prev, p1: true }));
                        } else if (entry.target === p2Ref.current) {
                            setInView(prev => ({ ...prev, p2: true }));
                        }
                    }
                });
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        if (spanRef.current) observer.observe(spanRef.current);
        if (p1Ref.current) observer.observe(p1Ref.current);
        if (p2Ref.current) observer.observe(p2Ref.current);

        return () => {
            if (spanRef.current) observer.unobserve(spanRef.current);
            if (p1Ref.current) observer.unobserve(p1Ref.current);
            if (p2Ref.current) observer.unobserve(p2Ref.current);
        };
    }, []);

    //third

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []);


    return (
        <>
            <div className="video-container">
                <video autoPlay loop muted className="background-video">
                    <source src={font4} type="video/mp4" />

                </video>
                <div className="content">


                    <section className="ae cj d2">


                        <div className="ae bp bq br bs ag ai aj bu bv bw bx by bz">

                            <h1 className="cq an b0 d3 d4 d5 d6 fade-in-up" style={{
                                textAlign: "center",
                                fontSize: "3rem",
                                fontWeight: "800",
                                marginTop: "6rem",
                                background: "linear-gradient(90deg, #137DC5, #BF1922)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                                backgroundSize: "200% 200%",
                                animation: "gradientMove 3s infinite",
                            }}>
                                WELCOME TO INNOVEXER <span style={{ color: "#BF1922 " }}>TECHCRAFT </span>PVT LTD
                            </h1>
                            <style>
                                {`
                                @keyframes gradientMove {
                                  0% {
                                background-position: 0% 50%;
                                 }
                                50% {
                                background-position: 100% 50%;
                                }
                                100% {
                                background-position: 0% 50%;
                                }
                                }
                               `}
                            </style>
                            <h2 className="d7 an d8 cs d9 d3 d4 da db cw fade-in-up-delay" style={{ color: "white", fontSize: "1rem", boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }}>
                                PLC-SCADA-HMI-VFD-SOFTWARE-SERVICES INSTRUMENTATION IT SOLUTION  CONTROL PANEL
                            </h2>

                            <div className="ae ag ah aj dc bu">
                                <Link to="/ContactForm" target="_blank"  >
                                    <button className="ae c3 dd cq de df as at ag au ah ai dg bu aw ax ay az b0 b1 b2 b3 b4 dh b6 b7 b8 b9 ba fade-in-up">
                                        Get In Touch
                                    </button>
                                </Link>
                                <Link href="https://www.youtube.com/watch?v=1uEJT3_M1Y0" target="_blank" rel="noopener noreferrer" className="di an aw dj b0 ct dk dl fade-in-up-delay">
                                    <button className="ae c3 dm at ag au ah ai dg bu df aw dn do cs b0 b1 b2 b3 b4 dp dq b6 b7 b8 b9 ba">
                                        <svg viewBox="0 0 24 24" className="ae dr c0 ds dt du">
                                            <path fill="#4E5D78" d="M17.8436076,12.841119 L8.54075759,18.8215226 C8.07618755,19.1201747 7.4574737,18.9856717 7.15882152,18.5211017 C7.05513038,18.3598044 7,18.1720957 7,17.9803441 L7,6.01953696 C7,5.46725221 7.44771525,5.01953696 8,5.01953696 C8.19175162,5.01953696 8.37946026,5.07466734 8.54075759,5.17835848 L17.8436076,11.1587621 C18.3081776,11.4574142 18.4426806,12.0761281 18.1440285,12.5406981 C18.0665625,12.6612008 17.9641102,12.763653 17.8436076,12.841119 Z" />
                                        </svg>
                                        Watch Video
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <section id="features" className="ae gf">
                <div className="ae bp bq br bs bu bv bw bx by bz">

                    <p
                        ref={p1Ref}
                        className={`cq an b0 d3 ao ap ${inView.p1 ? 'fade-in-right' : ''}`}
                    >
                        <span style={{ textAlign: "center", fontSize: "3rem", fontWeight: "800", marginTop: '6rem', color: "#4184b0" }}>Solutions we provide to our customers</span><br /><br />
                    </p>



                    <TicketCard />
                </div>
                <br /><br />
                <div>
                    <p
                        ref={p1Ref}
                        className={`cq an b0 d3 ao ap ${inView.p1 ? 'fade-in-right' : ''}`}
                    >
                        <span style={{ color: "#4184b0", textAlign: "center", fontSize: "3rem", fontWeight: "800", marginTop: '6rem' }} >Ours Clients</span><br /><br />
                    </p>
                </div>
                <CardImg />
            </section>

            <div className="ae bp bq br bs bu bv bw bx by bz ">
                <div className="ae h0 h1 dy ">
                    <div className="ae gk gl ag gm ">
                        <div className="gn er h2 h3 h4 h5 ae gs gt gu gv fg h6 ">
                            <img src={logo2} alt="atomize design system logo" style={{ height: "300px", width: "368px", borderRadius: "30px" }} />

                        </div>

                        <div className="gn er i7 i8 i9 ia ic ae gs gt gu gv ">

                            <div className="ae id ">


                                <h2 className="g0 an ie b0 d3 if ig ap  ">
                                    <span style={{ color: "#4184b0", flexWrap: "wrap" }}>We Are Open Intergrator </span>
                                </h2>

                                <div className="ae ">
                                    <div className="ae gk gl ag gm ">
                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={imm1} alt="atomize design system logo" style={{ height: "50px" }} />
                                                {/* <h3 className="f6 an b0 da db  ">Rockwell Atomation</h3> */}

                                            </div>
                                        </div>
                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={cimens} alt="atomize design system logo" style={{ height: "50px" }} />

                                            </div>
                                        </div>



                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={ava} alt="atomize design system logo" style={{ height: "80px" }} />


                                            </div>
                                        </div>
                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={bb1} alt="atomize design system logo" style={{ height: "80px" }} />


                                            </div>
                                        </div>
                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={ch} alt="atomize design system logo" style={{ height: "100px" }} />


                                            </div>
                                        </div>


                                    </div>

                                    <h2 className="g0 an ie b0 d3 if ig ap  ">

                                        <span style={{ color: "#4184b0", }}>Our Partner</span>
                                    </h2>
                                    <div className="ae gk gl ag gm ">
                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={yoko} alt="atomize design system logo" style={{ height: "80px" }} />

                                            </div>
                                        </div>
                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={honey} alt="atomize design system logo" style={{ height: "70px" }} />


                                            </div>
                                        </div>
                                        <div className="ih ii h4 h5 ae gs gt gu gv ">
                                            <div className="ae g0 ij ">
                                                <img src={hausar} alt="atomize design system logo" style={{ height: "100px" }} />


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="ae ">
                {/* <img src={bulb} alt="atomize design system logo" style={{height:"100px"}} /> */}

            </section>

            <section className="ae ">
                {/* <img src={bulb} alt="atomize design system logo" style={{height:"100px"}} /> */}
                <div className="ae bp bq br bs bu bv bw bx by bz ">

                    <div className="ae ag ai aj ">

                        {/* <h2 className="cy an jc b0 d3 d4 ao ap  ">A combination of tools to design and develop
                                modern applications at ease.</h2> */}
                    </div>
                    {/* <h2 className="cy an jc b0 d3 d4 ao ap  ">About Us
                        </h2> */}

                    {/* <ContactForm /> */}
                </div>
            </section>
            <div className="ae ">

                {/* <div
                    className="ae bp bq br bs bu bv bw bx by bz"
                    ref={sectionRef}
                >
                    <div className="ae jm jn jo ">
                        <div className="ae gk gl ag gm ">
                            <div className="gn er jp jq i9 ae gs gt gu gv ">
                                <div className="ae jr ck ">
                                    <div className="ae gk gl ag gm ">
                                        <div className="ih ii go gp gq gr ae gs gt gu gv ag ai aj ">
                                            <div className="ae aq ag ai aj js ">
                                                <p className="cq an jt d3 d4 ao ap">
                                                    <Counter end={1000} duration={2000} startCounting={isInView} />
                                                </p>
                                                <p className="di an cs d4 da db cw">Satisfied Customers</p>
                                            </div>
                                        </div>
                                        <div className="ih ii go gp gq gr ae gs gt gu gv ag ai aj ">
                                            <div className="ae aq ag ai aj js ">
                                                <p className="cq an jt d3 d4 ao ap">
                                                    <Counter end={150} duration={2000} startCounting={isInView} />
                                                </p>
                                                <p className="di an cs d4 da db cw">Energetic Team Members</p>
                                            </div>
                                        </div>
                                        <div className="ih ii go gp gq gr ae gs gt gu gv ag ai aj ">
                                            <div className="ae aq ag ai aj js ">
                                                <p className="cq an jt d3 d4 ao ap">
                                                    <Counter end={500} duration={2000} startCounting={isInView} />
                                                </p>
                                                <p className="di an cs d4 da db cw">Small & Big Projects</p>
                                            </div>
                                        </div>
                                        <div className="ih ii go gp gq gr ae gs gt gu gv ag ai aj ">
                                            <div className="ae aq ag ai aj js ">
                                                <p className="cq an jt d3 d4 ao ap">
                                                    <Counter end={10} duration={2000} startCounting={isInView} />
                                                </p>
                                                <p className="di an cs d4 da db cw">Years in business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="ae ju jv ">
                    <div className="ae bp bq br bs bu bv bw bx by bz ">
                        <div style={{ borderRadius: 56, backgroundColor: "#4184b0" }} className="ae jw jx jy jz k0 k1 ag bt aj k2 ju ">
                            <div className="ae ">
                                <p className="am an k3 je jf  " style={{ color: "black" }}> Get in Touch?🚀</p>
                                {/* <p className="g0 gw an cs da db  cw" style={{ borderRadius: 56, backgroundColor: "white", color: "black" }}>Contact us</p> */}
                            </div>
                            <div className="ae ag k4 "><Link to="/ContactForm" target="_blank" className="di an aw dj b0 ct dk dl"><button className="ae ec br bs ag au bt ai dg k5 aw ax c6 az b0 b1 k6 b2 b3 b4 eh b6 b7 b8 b9 ba">Contact Us

                            </button></Link></div>

                        </div>
                    </div>
                </div>
                <br />
            </div>
        </>
    )
}

export default Home