import React from 'react';
import '../styles/pdf.css'; // Optional: Style the cards using CSS.
import pdf1 from "../PDF/p1.pdf"
import pdf21 from "../PDF/p21.pdf"
import pdf31 from "../PDF/p31.pdf"
import imp1 from "../Image/2.png"
import imp2 from "../Image/2.png"
import imp3 from "../Image/2.png"
// import imp3 from "../Image/pdfimg6.jpg"


const PdfViewerPage = () => { 
  const pdfFiles = [
   
    { id: 1, title: 'BROCHURE ', file: pdf1 ,img: imp1},
    { id: 2, title: 'INSTRUMENTS', file: pdf21, img: imp2 },
    { id: 4, title: 'COMPANY PROFILE', file: pdf31,img:imp3 },
   
  ];

  const handleCardClick = (pdfFile) => {
    window.open(pdfFile, '_blank'); // Open the PDF in a new tab.
  };

  return (
    <div className="cards-container">
      {pdfFiles.map((pdf) => (
        <div key={pdf.id} className="card-in" onClick={() => handleCardClick(pdf.file)}
          // style={{
          //   background: `linear-gradient(145deg, rgba((22 141 196 , 0.85), rgba(230, 230, 230, 0.85))`
          // }}
          style={{
            background: `  url(${pdf.img}) no-repeat center/cover`
          }}
        >
          <h3>{pdf.title}</h3>
          <h3>CLICK HERE</h3>
         <p>Click here</p> 
        </div>
      ))}
    </div>
  );
};

export default PdfViewerPage;
