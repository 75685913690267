// WhatsAppButton.js
import React from 'react';
import '../styles/WhatsAppButton.css'; // Import the  CSS file
import whats from "../Image/whatsappp.png"

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open('https://wa.me/9315240709', '_blank'); // Replace YOUR_NUMBER with your WhatsApp number
  };

  return (
    <div className="whatsapp-container">
      <button className="whatsapp-button" onClick={handleClick}>
        <img
          src={whats}
          alt="WhatsApp"
          className="whatsapp-icon"
        />
      </button>
    </div>
  );
};

export default WhatsAppButton;
