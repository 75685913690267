// src/components/RegisterPage.jsx
import React, { useState } from 'react';
 
import '../styles/Register.css';

const RegisterPage = () => {
 
  return (
    <div className="register-container">
      <div className="register-page">
        <h2 style={{fontSize:"20px", fontWeight:"800"}}>Enquiry Form</h2>
        <form action="https://formsubmit.co/akg@innovexer.com" method='POST'>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name='name'
              required
            />
          </div>
          <div className="form-group">
            <label>Mobile No</label>
            <input
              type="text"
              name='mobile no'
              required
            />
          </div>
          <div className="form-group">
            <label>Brief</label>
            <input
              type="about"
              name='write somthing'
              required
            />
          </div>
          <button type="submit">SUBMIT</button>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
