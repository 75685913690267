 import React from 'react'
 
 const Event = () => {
   return (
     <>
     <div>Event</div>
     <div>Event</div>
     <div>Event</div>
     <div>Event</div>
     <div>Event</div>
     <div>Event</div>
     <div>Event</div>
     <div>Event</div>
     <div>Event</div>
     </>
   )
 }
 
 export default Event